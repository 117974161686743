body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 16px;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #303030;
}

dialog.mdl-dialog.firebaseui-dialog.firebaseui-id-dialog {
  height: fit-content !important;
  bottom: auto;
}
